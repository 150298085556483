@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  td {
    @apply py-3; 
  }
  th {
    @apply py-3;
  }
  h1{
    @apply text-lg font-bold;
  }
  input{
    @apply ring-2 ring-gray-300 rounded py-1 px-2 my-1 focus:outline-none focus:ring focus:ring-blue-300;
  }
  select{
    @apply ring-2 ring-gray-300 rounded py-1 px-2 my-1 focus:outline-none focus:ring focus:ring-blue-300;
  }
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}





